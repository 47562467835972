import { atom } from 'recoil';

export const RC_PerspectiveCustomers = atom({
  key: 'RC_PerspectiveCustomers',
  default: {
    visibleDrawer: false,
    data: []
  }
});

export const RC_PerspectiveCustomers_Options = atom({
  key: 'RC_PerspectiveCustomers_Options',
  default: {
    range: 1000,
  }
})