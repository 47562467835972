import { Button, notification } from "antd";
import { useEffect, useState } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import L from 'leaflet';

import { startWatchingGPSPosition } from "../utils/navigation";
import { getPeople } from "../utils/https";
import { DrawerToAddress } from "./DrawerToAddress";
import { MyMapComponent2 } from "./MyMapComponent2";
import { RC_PerspectiveCustomers, RC_PerspectiveCustomers_Options } from "../Recoil/PerspectiveCustomers";
import { RC_MapMarkers, RC_GPSCoordinates, RC_MapZoom } from "../Recoil/Map";
import {
  CarOutlined,
  UserOutlined,
} from '@ant-design/icons';

import IconRange from '../assets/range.png';
import IconTaxi from '../assets/taxi001.png';
import IconZoomIn from '../assets/zoom-in.png';
import IconZoomOut from '../assets/zoom-out.png';
import IconUser001 from '../assets/user001.png';
import IconCab001 from '../assets/cab001.png';

import './DriverMapView.css';

var iconCab = L.icon({
  iconUrl: IconCab001,
  // shadowUrl: 'leaf-shadow.png',

  iconSize: [64, 64], // size of the icon
  shadowSize: [50, 64], // size of the shadow
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 62],  // the same for the shadow
  popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const MapControls = () => {
  const [gpsCoordinates, setGPSCoordinates] = useRecoilState(RC_GPSCoordinates);
  const [perspectiveCustomers, setPerspectiveCustomers] = useRecoilState(RC_PerspectiveCustomers);
  const [zoom, setZoom] = useRecoilState(RC_MapZoom);
  
  return (
    <div className={perspectiveCustomers.visibleDrawer ? 'options optionsLeft' : 'options optionsRight'}>
      <Button
        shape="circle"
        className="control-button"
        onClick={() => setPerspectiveCustomers({ ...perspectiveCustomers, visibleDrawer: true })}
      >
        <img src={IconUser001} />
      </Button>
      <Button
        shape="circle"
        className="control-button"
        onClick={() => {
          setGPSCoordinates({ ...gpsCoordinates, update: Math.random() })
          setZoom(18);
        }}
      >
        <img src={IconCab001} />
      </Button>
      <Button
        shape="circle"
        className="control-button"
        onClick={() => setZoom(zoom + 1)}
      >
        <img src={IconZoomIn} />
      </Button>
      <Button
        shape="circle"
        className="control-button"
        onClick={() => setZoom(zoom - 1)}
      >
        <img src={IconZoomOut} />
      </Button>
      <Button
        shape="circle"
        className="control-button"
      >
        <img src={IconTaxi} />
      </Button>
      <Button
        shape="circle"
        className="control-button"
      >
        <img src={IconRange} />
      </Button>

    </div>
  )
}
export const DriverMapView = () => {
  const [perspectiveCustomers, setPerspectiveCustomers] = useRecoilState(RC_PerspectiveCustomers);
  const [zoom, setZoom] = useRecoilState(RC_MapZoom);
  const [gpsCoordinates, setGPSCoordinates] = useRecoilState(RC_GPSCoordinates);
  const [markers, setMarkers] = useRecoilState(RC_MapMarkers);
  const [perspectiveCustomersOptions, setPerspectiveCustomersOptions] = useRecoilState(RC_PerspectiveCustomers_Options);

  const [drawerToAddress, setDrawerToAddress] = useState({
    visible: false
  });

  const _getPeople = async () => {
    let data = await getPeople({
      lat: gpsCoordinates.lat || 2,
      lng: gpsCoordinates.lng || 40,
      range: perspectiveCustomersOptions.range,
    });
    
    setPerspectiveCustomers({
      ...perspectiveCustomers,
      data
    });
  }

  const startInterval = () => {
    return setInterval(async () => {
      _getPeople();
    }, 1000);
  }


  const updateMarkers = () => {
    if (perspectiveCustomers.data.length > 0) {
      setMarkers(perspectiveCustomers.data.map(marker => ({ lat: marker.lat, lng: marker.lng })));
    }
  }

  const GPSUpdatePosition = data => {
    if (
      (gpsCoordinates.lat !== data.coords.latitude)
      || (gpsCoordinates.lng !== data.coords.longitude)
      // || (gpsCoordinates.update !== data.coords.update)
    ) {
      setGPSCoordinates({
        lat: data.coords.latitude,
        lng: data.coords.longitude,
        ...data.coords
      });
    }
  }
  const GPSError = e => console.log('GPSError', e);

  useEffect(updateMarkers, [perspectiveCustomers]);

  useEffect(() => {
    _getPeople();
    let timer = startInterval();

    let navigatorId = startWatchingGPSPosition({ success: GPSUpdatePosition, error: GPSError, })

    return () => {
      clearInterval(timer);
      if (navigatorId !== -1) navigator.geolocation.clearWatch(navigatorId);
    }
  }, [])

  const getCurrentPosition = () => {
    return {
      ...gpsCoordinates,
      icon: iconCab,
    }
  }

  return (
    <>
      <MapControls />
      <MyMapComponent2
        markers={markers}
        autoFocusOnOff={false}
        draggable={false}
        showCurrentPosition={true}
        currentPositionMarker={getCurrentPosition()}
        zoom={zoom}
      />
      <DrawerToAddress
        customers={perspectiveCustomers}
        visible={drawerToAddress.visible}
        onClose={() => setPerspectiveCustomers({ ...perspectiveCustomers, visibleDrawer: false })}
      />
    </>
  )
}