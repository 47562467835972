import { faker } from '@faker-js/faker';

faker.locale = 'el';

export const getPeople = ({
  lat,
  lng,
  range
}) => {

  let arr = [];
  let randomResults = Math.floor(Math.random() * 20)+1;
  for (let n = 0; n < randomResults; n++) {
    arr.push({
      id: Math.random(),
      lat: lat + (Math.random()/100)*(Math.random() > 0.5 ? 1 : -1),
      lng: lng + (Math.random()/100)*(Math.random() > 0.5 ? 1 : -1),
      name: faker.name.firstName() + ' ' + faker.name.lastName(),
      photo: faker.image.imageUrl(),
      phones: faker.phone.number(),
      takeTo: faker.address.secondaryAddress(),
    });
  }

  return Promise.resolve(arr);
}