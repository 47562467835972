export const startWatchingGPSPosition = ({ success, error }) => {
  if (navigator.geolocation) {
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0
    };

    return navigator.geolocation.watchPosition(success, error, options);
  }

  return -1;
}