import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { atom } from 'recoil';
import Leaflet, { map } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap, ZoomControl } from 'react-leaflet'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';

import { translate } from '../utils/translate';

import 'leaflet/dist/leaflet.css';

const RecoilMyMapComponent = atom({
  key: 'RecoilMyMapComponent',
  default: {
    lat: 22,
    lng: 23,
  }
});


let DefaultIcon = Leaflet.icon({
  ...Leaflet.Icon.Default.prototype.options,
  iconUrl: icon,
  iconRetinaUrl: iconRetina,
  shadowUrl: iconShadow
});
Leaflet.Marker.prototype.options.icon = DefaultIcon;

Leaflet.Icon.Default.imagePath = '˜/node_modules/leaflet';
delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function LocationMarker() {
  const [position, setPosition] = useState(null);

  const map = useMapEvents({
    click() {
      map.locate()
    },
    locationfound(e) {
      setPosition(e.latlng)
      map.flyTo(e.latlng, map.getZoom())
    },
  })

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>?
    </Marker>
  )
}

const convertToGoogleMapsLatLng = e => ({
  latLng: {
    lat: () => e.latlng.lat,
    lng: () => e.latlng.lng,
  }
});

export const MyMapComponent2 = ({
  addMarker = () => console.log('add a function when adding a marker'),
  autoFocusOnOff,
  currentPositionMarker,
  markers = [],
  showCurrentPosition = false,
  onClickMarker = () => console.log('should setup onClickMarker'),
  updateDraggableMarker = () => console.log('should set updateDraggableMarker'),
  draggable = true,
  zoom = 13,
}) => {

  const [focusMapCloseToCurrentPosition, setFocusMapCloseToCurrentPosition] = useState();
  const [focusCenter, setFocusCenter] = useState();

  const centerInEurope = {
    lat: -34.397,
    lng: 150.644
  };

  const MapEvents = () => {
    const map = useMapEvents({
      click: e => {
        onClickMarker(e);
        addMarker(convertToGoogleMapsLatLng(e));
        map.flyTo(e.latlng, 13)
      }
    })

    return null;
  }

  function DraggableMarker({ idx, lat, lng }) {
    const [position, setPosition] = useState({
      lat, lng,
    })
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            updateDraggableMarker(
              {
                latLng: {
                  lat: () => marker.getLatLng().lat,
                  lng: () => marker.getLatLng().lng,
                },
              },
              lat,
              lng,
            );
          }
        },
        click: e => {
          onClickMarker(convertToGoogleMapsLatLng(e))
        }
      }),
      [],
    )

    return <Marker
      key={idx}
      eventHandlers={eventHandlers}
      position={{ lat, lng }}
      draggable
      position={position}
      ref={markerRef}
    >
      <Popup minWidth={90}>
        'Marker is draggable'
      </Popup>
    </Marker>
  }

  useEffect(() => {
    if (currentPositionMarker && currentPositionMarker.lat && currentPositionMarker.lng) {
      if (!focusMapCloseToCurrentPosition) setFocusMapCloseToCurrentPosition(currentPositionMarker);
      setFocusCenter(currentPositionMarker);
    }
  }, [currentPositionMarker]);

  const getCurrentCenter = () => {
    if (focusCenter) return { lat: focusCenter.lat, lng: focusCenter.lng };
    if (autoFocusOnOff) return currentPositionMarker;
    if (focusMapCloseToCurrentPosition) return focusMapCloseToCurrentPosition;
    return centerInEurope;
  }
  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  const SimpleMarkers = () => {
    if (draggable) return null;
    if ((!markers) || (markers.length === 0)) return null;

    return (
      markers.map((marker, idx) =>
        <Marker key={idx} idx={idx} position={[marker.lat, marker.lng]} />
      )
    )
  }

  const DraggableMarkers = () => {
    if (!draggable) return null;
    if (!markers) return null;

    return (
      markers.map((marker, idx) =>
        <DraggableMarker key={idx} idx={idx} lat={marker.lat} lng={marker.lng} />
      ))
  }
  return (
    <MapContainer
      className="MapComponentLeaflet"
      center={getCurrentCenter()}
      zoom={zoom}
      zoomControl={false}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {/* <ZoomControl position='bottomright' /> */}
      {zoom && <ChangeView center={getCurrentCenter()} zoom={zoom} />}
      <MapEvents />
      {(showCurrentPosition || autoFocusOnOff) && currentPositionMarker.lat && currentPositionMarker.lng &&
        <Marker
          position={{
            lat: currentPositionMarker.lat,
            lng: currentPositionMarker.lng,
          }}
          icon={currentPositionMarker.icon || null}
          style={{
            color: 'red'
          }}
        >
          <Popup>
            {translate('youAreHere')}
          </Popup>
        </Marker>
      }

      <DraggableMarkers />
      <SimpleMarkers />
    </MapContainer>
  )
}