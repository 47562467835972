import logo from './logo.svg';
import './App.css';
import { DriverMapView } from './Components/DriverMapView';
import { RecoilRoot } from 'recoil';


function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <DriverMapView />
      </div>
    </RecoilRoot>
  );
}

export default App;
