import { Drawer } from "antd";
import { useRecoilValue } from "recoil";
import { RC_PerspectiveCustomers } from '../Recoil/PerspectiveCustomers';

export const DrawerToAddress = ({
  visible = false,
  onClose,
}) => {
  const perspectiveCustomers = useRecoilValue(RC_PerspectiveCustomers);
  
  return (
    <Drawer
      placement="right"
      visible={perspectiveCustomers.visibleDrawer}
      onClose={onClose}
    >
      {perspectiveCustomers.data.map( (customer,idx) => (
        <div key={idx}>
          <h3>{customer.takeTo}</h3>
          <h5>{customer.name}</h5>
        </div>
      ))}
    </Drawer>
  )
}