import { atom } from "recoil";

export const RC_GPSCoordinates = atom({
  key: 'RC_GPSCoordinates',
  default: {}
});


export const RC_MapZoom = atom({
  key: 'RC_MapZoom',
  default: 12
});

export const RC_MapMarkers = atom({
  key: 'RC_MapMarkers',
  default: []
});
